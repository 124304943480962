<template>
    <div ref="printPane" :style="paneStyle" class="buttonContainer">
        <!-- row for the radiobuttons to change between tabs -->
        <div id="radioRow">
            <span>
                <input
                    class="radioButton"
                    id="singlePrint"
                    type="radio"
                    value="singlePrint"
                    v-model="printFormat"
                />
                <label class="label" for="singlePrint"
                    >Print screen to PDF</label
                >
            </span>
            <span v-show="canPrintMapbook">
                <input
                    class="radioButton"
                    id="mapbookPrint"
                    type="radio"
                    value="mapbookPrint"
                    v-model="printFormat"
                />
                <label class="label" for="mapbookPrint">Print mapbook</label>
            </span>
        </div> 
        <div id="radioRow">          
            <span v-show="canReceptorExport">
                <input
                    class="radioButton"
                    id="receptorExport"
                    type="radio"
                    value="receptorExport"
                    v-model="printFormat"
                />
                <label class="label" for="mapbookPrint">Export receptors</label>
            </span>            
        </div>
        <!-- layout for single page print -->
        <div v-show="printFormat == 'singlePrint'">
            <div class="descText">
                Press print to generate a PDF of current view and visible
                layers. This PDF can be used to print or download. Make sure
                <b>pop-ups</b> are allowed in your browser.
            </div>
            <div class="EM">
                <custom-floating-label
                    :config="{
                        label: 'Map Title',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        type="text"
                        class="attributeEntry mb-4"
                        v-model="mapTitle"
                    />
                </custom-floating-label>
                <custom-floating-label
                    :config="{
                        label: 'Second Map Title',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        type="text"
                        class="attributeEntry mb-4"
                        v-model="mapTitle2"
                    />
                </custom-floating-label>
                <custom-floating-label
                    :config="{
                        label: 'Project Number',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        type="text"
                        class="attributeEntry mb-4"
                        v-model="projectNumber"
                    />
                </custom-floating-label>
                <custom-floating-label
                    :config="{
                        label: 'Client',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        type="text"
                        class="attributeEntry mb-4"
                        v-model="clientName"
                    />
                </custom-floating-label>
                <custom-floating-label
                    :config="{
                        label: 'Project Name',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        type="text"
                        class="attributeEntry mb-4"
                        v-model="projectName"
                    />
                </custom-floating-label>
                <custom-floating-label
                    :config="{
                        label: 'Second Project Name',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        type="text"
                        class="attributeEntry mb-4"
                        v-model="projectName2"
                    />
                </custom-floating-label>
                <custom-floating-label
                    :config="{
                        label: 'Figure Number',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        type="text"
                        class="attributeEntry mb-4"
                        v-model="figureNumber"
                    />
                </custom-floating-label>
                <custom-floating-label
                    :config="{
                        label: 'Template',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select v-model="template" class="printInput">
                        <option>Portrait</option>
                        <option>Landscape</option>
                    </select>
                </custom-floating-label>
                <!-- don't need this now, have to add draft to the layout somehow -->
                <!-- <div> Draft?
            <input
                type="checkbox"
                v-model="printDraft"
            />
        </div> -->
                <div class="d-flex justify-content-between">
                    <button
                        class="btn btn-unselected btn-draw"
                        @click="startPrint"
                        :disabled="isPrinting"
                    >
                        <span v-if="isPrinting">Generating...</span
                        ><span v-else>Generate</span>
                    </button>
                    <button
                        class="btn btn-unselected btn-draw"
                        v-if="singlePagePrintJobID != '' && !isPrinting"
                        @click="downloadMapPDF(singlePagePrintJobID)"
                    >
                        Open PDF
                    </button>
                </div>
            </div>
        </div>
        <!-- layout for mapbook print entry -->
        <div v-show="printFormat == 'mapbookPrint'">
            <div class="descText">
                Press print to generate a mapbook PDF of current view preset
                layer groups. This PDF can be used to print or download. Make
                sure <b>pop-ups</b> are allowed in your browser.
            </div>
            <span v-if="isPrintingMapBook">
                Completed Tasks: {{ mapbookTaskIDs.length - jobIDs.length }} /
                {{ mapbookTaskIDs.length }}
            </span>
            <div class="d-flex justify-content-between">
                <button
                    class="btn btn-unselected btn-draw"
                    @click="mapBookPrint"
                    :disabled="isPrintingMapBook"
                >
                    <span v-if="isPrintingMapBook">Generating...</span>
                    <span v-else>Generate</span>
                </button>
                <button
                    class="btn btn-unselected btn-draw"
                    v-if="mapbookJobID != '' && !isPrintingMapBook"
                    @click="downloadMapPDF(mapbookJobID)"
                >
                    Open PDF
                </button>
            </div>
        </div>
        <!-- layout for receptor export -->
        <div v-show="printFormat == 'receptorExport'">
            <div class="descText">
                Submit a request to generate a receptor export workbook
                of the current views preset layer groups. This workbook
                can be used to print or download. Make sure
                <b>pop-ups</b> are allowed in your browser.
            </div>
            <div class="d-flex justify-content-between">
                <button
                    class="btn btn-unselected btn-draw"
                    @click="receptorExport"
                    :disabled="isExportingReceptors"
                >
                    <span v-if="isExportingReceptors">Generating...</span>
                    <span v-else>Generate</span>
                </button>
                <a  
                    :href="receptorLink"
                    :download="receptorFileName"
                    class="btn btn-unselected btn-draw"  
                    v-if="receptorLink != '' && !isExportingReceptors"  
                    role="button"  
                >  
                    Download  
                </a>                
            </div>
        </div>        
    </div>
</template>

<script>
import axios from 'axios';
import CustomFloatingLabel from '../CustomFloatingLabel.vue';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { apiConstants } from '../../common/apiConst.js';
import { projectService } from '../../services/project.service';
import { datasetService } from '../../services/dataset.service';
import { spatialFunctions } from '../../utilities/spatialFunctions';
import { commonService } from '../../services';
export default {
    name: 'PrintPane',
    components: {
        CustomFloatingLabel,
    },
    mixins: [],
    props: {},
    data() {
        return {
            printFormat: 'singlePrint',
            mapTitle: '',
            mapTitle2: '',
            projectNumber: '',
            clientName: '',
            projectName: '',
            projectName2: '',
            figureNumber: '',
            template: 'Portrait',
            printDraft: false,
            singlePagePrintJobID: '',
            mapbookJobID: '',
            isPrinting: false,
            isPrintingMapBook: false,
            isExportingReceptors: false,
            receptorLink: '',
            receptorFileName: '',
            jobIDs: [],
            width: 400,
            mapbookTaskIDs: [],
            canPrintMapbook: false,
            canReceptorExport: false,
        };
    },
    mounted() {
        this.$parent.$emit('ready', this.mapObject);
        this.canPrintMapbookMethod();
    },
    render() {
        return null;
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        downloadMapPDF(downloadJobID) {
            window.open(
                'https://djangodi-prod.azurewebsites.net/geoserver/getPDF/' +
                    downloadJobID,
                '_blank'
            );
        },
        async downloadFile(docId) {
            var response = await commonService.getDocumentLinkByID(docId);
            return response;
        },
        async getFileName(docId) {
            var response = await commonService.getDocument(docId);
            return response.data.FileName;
        },        
        async checkStatusUntilComplete(jobId) {
            let continueLoop = true;

            try {
                while (continueLoop) {
                    const statusResponse = await datasetService.getStatus(
                        jobId
                    );
                    const status = statusResponse.data;
                    switch (status) {
                        case 'Completed':
                            var response = await datasetService.getQueue(jobId);
                            var functionReturnStatus = JSON.parse(
                                response.data.FunctionReturnStatus
                            );
                            var docId = +functionReturnStatus.Data;
                            var docHref = await this.downloadFile(docId);
                            var fileName = await this.getFileName(docId);
                            var link = await commonService.getDownloadLink(
                                docHref,
                                fileName
                            );
                            this.receptorLink = link;
                            this.receptorFileName = fileName;
                            //this.receptorDownloadLink = link;
                            this.isExportingReceptors = false;
                            continueLoop = false;
                            break;
                        case 'Error':
                            this.isExportingReceptors = false;
                            continueLoop = false;
                            break;
                        case 'Unkown JobID':
                            this.isExportingReceptors = false;
                            continueLoop = false;
                            break;
                        case 'Queued':
                            break;
                        case 'Processing':
                            break;
                        default:
                            this.isExportingReceptors = false;
                            continueLoop = false;
                            break;
                    }
                    await new Promise((resolve) => setTimeout(resolve, 5000));
                }
            } catch (error) {
                this.isExportingReceptors = false;
            }
        },        
        async receptorExport() {
            this.receptorLink = "";
            this.receptorFileName = "";            
            this.isExportingReceptors = true;
            
            let requestBody = {
                "layerIds": [],
                "bbox": [
                    String(spatialFunctions.lng2meters(this.mapExtent._southWest.lng)), 
                    String(spatialFunctions.lat2meters(this.mapExtent._southWest.lat)), 
                    String(spatialFunctions.lng2meters(this.mapExtent._northEast.lng)), 
                    String(spatialFunctions.lat2meters(this.mapExtent._northEast.lat))],
                "projectId": this.ProjectID,
                "isMapbook": true
            }

            const response = await datasetService.initializeRequest(
                this.ProjectID,
                'ReceptorExport',
                requestBody
            );
            const jobId = response.data.Data;

            await this.checkStatusUntilComplete(jobId);

        },
        singlePagePrint(printJSON) {
            const json2 = JSON.stringify(printJSON);
            const queueService = 'Map Screen print';
            this.setToastMessage({
                queueService: queueService,
                queueState: 'start',
                queueMessage: 'started.',
                downloadLink: '',
                visible: true,
            });
            axios({
                method: 'post',
                headers: {
                    Authorization: 'none',
                    'Content-Type': 'application/json',
                },
                url:
                    'https://djangodi-prod.azurewebsites.net/geoserver/printMap/',
                data: json2,
            })
                .then((init_response) => {
                    setTimeout(() => {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'progress',
                            queueMessage: 'in progress!',
                            downloadLink: '',

                            visible: true,
                        });
                    }, '1000');

                    this.jobid = init_response.data.taskID;
                    var printStatusIntervalID = setInterval(() => {
                        axios({
                            method: 'get',
                            headers: { Authorization: 'none' },
                            url:
                                'https://djangodi-prod.azurewebsites.net/geoserver/checkStatus/' +
                                this.jobid,
                        }).then((status_response) => {
                            const printStatus = status_response.data.status;

                            if (printStatus == 200) {
                                this.singlePagePrintJobID = this.jobid;
                                this.setToastMessage({
                                    queueService: queueService,
                                    queueState: 'complete',
                                    queueMessage: 'completed.',
                                    downloadLink:
                                        ' <a href="https://djangodi-prod.azurewebsites.net/geoserver/getPDF/' +
                                        this.singlePagePrintJobID +
                                        '" target="_blank">Open Map</a>',
                                    visible: true,
                                });
                                this.isPrinting = false;
                                clearInterval(printStatusIntervalID);
                            }
                        });
                    }, 5000);
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'error',
                            queueMessage: error.response,
                            downloadLink: '',

                            visible: true,
                        });
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'error',
                            queueMessage: error.request,
                            downloadLink: '',

                            visible: true,
                        });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'error',
                            queueMessage: error.request,
                            downloadLink: '',

                            visible: true,
                        });
                    }
                });
        },
        mapBookPrint() {
            if (!this.canPrintMapbook) {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage:
                        'This project does not support MapBook feature.',
                });
                return;
            }
            this.mapbookJobID = '';
            const printJSON = {
                ENV: this.environment_ref,
                centerX: this.mapCenter.lat,
                centerY: this.mapCenter.lng,
                mapZoom: this.mapZoom,
                minX: this.mapExtent._southWest.lng,
                minY: this.mapExtent._southWest.lat,
                maxX: this.mapExtent._northEast.lng,
                maxY: this.mapExtent._northEast.lat,
                srcwidth: this.mapSize.x,
                srcheight: this.mapSize.y,
                ProjectID: this.ProjectID,
            };
            this.isPrintingMapBook = true;
            const queueService = 'Map Book print';
            axios({
                method: 'post',
                headers: {
                    Authorization: 'none',
                    'Content-Type': 'application/json',
                },
                url:
                    'https://djangodi-prod.azurewebsites.net/geoserver/mapBook/',
                data: JSON.stringify(printJSON),
            }).then((init_response) => {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'start',
                    queueMessage: 'started.',
                    downloadLink: '',
                    visible: true,
                });
                this.jobIDs = init_response.data.taskIDs;
                this.mapbookTaskIDs = this.jobIDs.slice(0);
                var mapbookTaskIdInterval = setInterval(() => {
                    if (this.jobIDs.length === 0) {
                        clearInterval(mapbookTaskIdInterval);
                        this.mapbookJobID = this.mapbookTaskIDs[
                            this.mapbookTaskIDs.length - 1
                        ];
                        this.isPrintingMapBook = false;
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'complete',
                            queueMessage: 'completed.',
                            downloadLink:
                                '<a href="https://djangodi-prod.azurewebsites.net/geoserver/getPDF/' +
                                this.mapbookJobID +
                                '" target="_blank">Open Map</a>',
                            visible: true,
                        });
                    }
                    axios({
                        method: 'get',
                        headers: { Authorization: 'none' },
                        url:
                            'https://djangodi-prod.azurewebsites.net/geoserver/checkStatus/' +
                            this.jobIDs[0],
                    }).then((status_response) => {
                        const printStatus = status_response.data.status;
                        if (printStatus == 200) {
                            this.setToastMessage({
                                queueService: queueService,
                                queueState: 'progress',
                                queueMessage:
                                    'in progress... ' +
                                    (this.mapbookTaskIDs.length -
                                        this.jobIDs.length) +
                                    '/' +
                                    this.mapbookTaskIDs.length,
                                visible:
                                    this.jobIDs.length ==
                                    this.mapbookTaskIDs.length
                                        ? true
                                        : '',
                                downloadLink: '',
                            });
                            var index = this.jobIDs.indexOf(this.jobIDs[0]);
                            this.jobIDs.splice(index, 1);
                        }
                    });
                }, 5000);
            });
        },
        clearPrintTitleBlock() {
            this.mapTitle = '';
            this.mapTitle2 = '';
            this.projectNumber = '';
            this.clientName = '';
            this.projectName = '';
            this.projectName2 = '';
            this.figureNumber = '';
            this.template = 'Landscape';
        },
        startPrint() {
            this.singlePagePrintJobID = '';
            this.isPrinting = true;
            const printJSON = {
                user_mapTitle: this.mapTitle,
                user_mapTitle2: this.mapTitle2,
                user_projectNumber: this.projectNumber,
                user_client: this.clientName,
                user_projectName: this.projectName,
                user_projectName2: this.projectName2,
                user_figureNumber: this.figureNumber,
                ENV: this.environment_ref,
                ProjectID: this.ProjectID,
                layout: this.template,
                title: this.mapTitle,
                map: {
                    srcwidth: this.mapSize.x,
                    srcheight: this.mapSize.y,
                    projection: 'EPSG:3857',
                    zoom: this.mapZoom,
                    center: [this.mapCenter.lat, this.mapCenter.lng],
                    bbox: [
                        this.mapExtent._southWest.lng,
                        this.mapExtent._southWest.lat,
                        this.mapExtent._northEast.lng,
                        this.mapExtent._northEast.lat,
                    ],
                },
            };
            var ActiveWMSlayers = [];
            this.wmsLayers.forEach((element) => {
                if (
                    element.isChecked &&
                    this.mapZoom >= element.minZoom &&
                    this.mapZoom <= element.maxZoom
                ) {
                    var version = '';
                    if (
                        element.url.startsWith(
                            'https://geoserver.adapt.ghd-digital.com'
                        )
                    ) {
                        version = 'new';
                    } else {
                        version = 'old';
                    }
                    const workspace = element.url.match(
                        new RegExp('geoserver/' + '(.*)' + '/wms')
                    )[1];
                    var layer = element.layers.replace(workspace + ':', '');
                    var style = element.styles.replace(workspace + ':', '');
                    var printLayer = {
                        title: element.name,
                        workspace: workspace,
                        layer: layer,
                        style: style,
                        version: version,
                    };
                    ActiveWMSlayers.push(printLayer);
                }
            });
            printJSON['WMSlayers'] = ActiveWMSlayers;
            this.singlePagePrint(printJSON);
        },
        paneReady() {},
        canPrintMapbookMethod() {
            projectService.CanPrintMapbook(this.ProjectID).then((res) => {
                this.canPrintMapbook = res.data;
                this.canReceptorExport = res.data;
            });
        },
        ...mapMutations('common', ['setQueueMessage']),
    },
    computed: {
        ...mapState('emergencyManagement', {
            mapbookJobIDs: (state) => state.mapbookJobIDs,
            layersStruct: 'layersStructValues',
        }),
        ...mapState('projects', {
            ClientPrintLogo: (state) =>
                state.selectedProjects[0].ClientPrintLogo,
            mapZoom: (state) => state.mapData.mapZoom,
            mapCenter: (state) => state.mapData.mapCenter,
            mapSize: (state) => state.mapData.mapSize,
            mapExtent: (state) => state.mapData.mapExtent,
        }),
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
        paneStyle: function() {
            return {
                position: 'absolute',
                width: '100%',
            };
        },
        wmsLayers: function() {
            var layers = [];
            try {
                this.layersStruct.forEach((layer) => {
                    if (layer.layers != '' && layer.url != '') {
                        layers.push(layer);
                    }
                });
            } catch (e) {
                return [];
            }
            return layers;
        },
        environment_ref: function() {
            switch (apiConstants.api_url) {
                case 'http://localhost:54654/api/':
                    return 'dev';
                case 'https://coreservicesapi-dev.adapt.ghd.com/api/':
                    return 'dev';
                case 'https://coreservicesapi-qa.adapt.ghd.com/api/':
                    return 'qa';
                case 'https://coreservicesapi-uat.adapt.ghd.com/api/':
                    return 'uat';
                case 'https://coreservicesapi.adapt.ghd.com/api/':
                    return 'prd';
                default:
                    return 'dev';
            }
        },
    },
    watch: {
        ProjectID(newProjectId) {
            this.canPrintMapbookMethod();
            this.clearPrintTitleBlock();
            this.printFormat = 'singlePrint';
        },
    },
};
</script>

<style scoped>
.buttonContainer {
    padding: 20px;
    background-color: white;
    height: 100%;
    z-index: 9999;
}
.btn-unselected {
    color: white;
    background-color: #006395;
}
.btn-unselected:hover {
    color: white;
    background-color: #00496d !important;
}
.btn-unselected:active {
    color: white;
    background-color: #00496d !important;
}
.btn-selected {
    color: white;
    background-color: #00496d;
}
.btn-selected:hover {
    color: white;
}
.btn-selected:active {
    color: white;
}
@media only screen and (max-height: 800px) {
    .buttonContainer {
        overflow-y: scroll;
    }
}
@media only screen and (min-height: 800px) and (max-height: 900px) {
    .buttonContainer {
        overflow-y: scroll;
    }
}
@media only screen and (min-height: 900px) and (max-height: 1000px) {
    .buttonContainer {
        overflow-y: scroll;
    }
}
@media only screen and (min-height: 1000px) and (max-height: 1200px) {
    .buttonContainer {
        overflow-y: scroll;
    }
}
.radioButton {
    display: inline-block;
}
#radioRow {
    display: flex;
    justify-content: space-around;
    padding-bottom: 5px;
}
.distanceEntry {
    width: 136px;
    border-radius: 4px;
    border: solid 1px lightgray;
    margin-left: 5px;
}
.btn-gray {
    background-color: gray !important;
    color: white;
    height: 42px;
}
.btn-buffer {
    margin-left: 10px;
}
.btn-draw {
    margin-top: 7px;
}
.label {
    margin-left: 5px;
}
.rowBtns {
    padding-top: 10px;
    padding-bottom: 10px;
}
.icsInput {
    width: 330px;
    border-radius: 4px;
    margin-left: 15px;
    margin-top: 8px;
    margin-bottom: 0 !important;
}
.inputBuffer {
    margin-top: 5px;
    border-radius: 4px;
    width: 100%;
}
.descText {
    padding: 20px;
    padding-bottom: 20px;
}
.EM {
    padding-left: 20px;
}
.printInput {
    border: none !important;
    padding-top: 15px !important;
}
</style>
